import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import NavigationAlt from "../components/layout/NavigationAlt";
import {LocomotiveScrollProvider} from "react-locomotive-scroll";
import PageTransition from "../components/layout/PageTransition";
import {graphql, Link, useStaticQuery} from "gatsby";
import SEO from "../components/helper/SEO";
import Text from "../components/helper/MyPortableText";


const Layout = ({ children, location }) => {
    const ref = useRef(null);

    const options = {
        smooth: true,
    }

    const {allSanitySettings} = useStaticQuery(graphql`
        query siteSettings {
            allSanitySettings{
                edges {
                    node {
                        maintenance
                        maintenance_seo_description
                        maintenance_seo_title
                        maintenancetitle
                        maintenancebutton {
                            value
                            title
                        }
                        _rawMaintenancetext
                    }
                }
            }
        }
    `)

    const [maintenanceMode, setMaintenanceMode] = useState(true);

    useEffect(() => {
        if (location?.hostname !== 'danielasengstbratl.at') {
            setMaintenanceMode(false);
        }
    }, [location]);

    const staticSettings = allSanitySettings.edges[0].node;

    if (staticSettings?.maintenance && maintenanceMode) {
        console.log('maintenance mode aktiv')
        return (<>
            <SEO title={staticSettings?.maintenance_seo_title} description={staticSettings?.maintenance_seo_description} location={location}></SEO>
            <div className={"maintenance top-0 left-0 h-screen w-screen bg-white flex justify-center items-center flex-col"} style={{height: '100vh', width: '100vw'}}>
                <div className="reveal">
                    <h1 className={`font-extralight text-5xl tablet:text-7xl z-10 relative mb-5 center-aligned reveal`}>{staticSettings.maintenancetitle}</h1>
                </div>
                <div className={"text-center reveal"}>
                    <Text value={staticSettings._rawMaintenancetext}></Text>
                </div>
                <div className={"flex justify-center reveal"}>
                    <Link className="link font-semibold tracking-wider uppercase text-xs mt-5 hover:-translate-y-0.5 duration-500 ease-in-out text-center inline-block" to={staticSettings?.maintenancebutton?.value}>{staticSettings?.maintenancebutton?.title}</Link>
                </div>
            </div>
        </>)
    } else {
        console.log('maintenance mode inaktiv')
        return (<LocomotiveScrollProvider options={options} containerRef={ref}>
            <main data-scroll-container ref={ref}>
                <NavigationAlt location={location}></NavigationAlt>
                <PageTransition location={location}>
                    {children}
                </PageTransition>
            </main>
        </LocomotiveScrollProvider>)
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
