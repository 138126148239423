import { PortableText } from '@portabletext/react';
import React from 'react';
import {Link} from "gatsby";

const textComponents = {
    block: {
        normal: ({ children }) => <p className={"font-light text-base leading-relaxed mb-5"}>{children}</p>,
        h1: ({ children }) => <h1>{children}</h1>,
        h2: ({ children }) => <h2>{children}</h2>,
        h3: ({ children }) => <h3>{children}</h3>,
        h4: ({ children }) => <h4 className={"font-semibold text-brown text-base uppercase mt-5 tracking-wider"}>{children}</h4>,
        h5: ({ children }) => <h5>{children}</h5>,
        h6: ({ children }) => <h6>{children}</h6>,
    },
    marks: {
        strong: ({children}) => <span className={"font-semibold"}>{children}</span>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className="underline inline-block hover:-translate-y-0.5 duration-500 ease-in-out cursor-pointer">
                    {children}
                </a>
            )
        },
        internalLink: ({value, children}) => {
            return (
                <Link to={value?.fixedpage} className="underline inline-block hover:-translate-y-0.5 duration-500 ease-in-out cursor-pointer">
                    {children}
                </Link>
            )
        },
    },
};

function Text({ value }) {
    return <PortableText value={value} components={textComponents} />;
}

export default Text;
