import React, {useEffect, useRef, useState} from "react";
import Icon from "../helper/Icon";
import {graphql, Link, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';
import DatenschutzAcceptance from "./DatenschutzAcceptance";
import {useLocomotiveScroll} from "react-locomotive-scroll";


const Menu = styled.div`
  display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const MenuInner = styled.div`
  transform: ${({nav2}) => (nav2 ? "translateX(0)" : "translateX(100%)")} !important;
`

const NavigationAlt = ({location}) => {

    const {allSanitySettings, allSanityDienstleistungen} = useStaticQuery(graphql`
        query site {
            allSanitySettings {
                edges {
                    node {
                        navigation_title
                        navigation_subtitle
                        sociallinks {
                            title
                            type
                            value
                        }
                        cookies {
                            button_selection
                            button_all
                            title
                            _rawText
                        }
                    }
                }
            }
            allSanityDienstleistungen {
                edges {
                    node {
                        title
                        order
                        slug{
                            current
                        }
                    }
                }
            }
        }
    `)

    const settings = allSanitySettings.edges[0].node;
    const dienstleistungen = allSanityDienstleistungen.edges;
    console.log(dienstleistungen);

    let opacity = true;
    let noImageHeader = false;

    const [nav, changeNavState] = useState(false);
    const [nav2, changeNavState2] = useState(false);
    const [navBg, changeNavBgState] = useState(opacity);


    const menuRef = useRef();
    const menuInnerRef = useRef();

    const { scroll } = useLocomotiveScroll()

    const changeNav = () => {
        if (nav) {
            document.body.classList.remove("stop-scrolling");
            menuRef.current.classList.add('fade-out');
            changeNavState2(false);
            menuInnerRef.current.classList.remove('open');

            setTimeout(() => {
                changeNavState(false);
                if (menuRef.current) {
                    menuRef.current.classList.remove('fade-out');
                }
            }, 300)
        } else {
            document.body.classList.add("stop-scrolling");
            changeNavState(true);
            changeNavState2(true);
        }
    }

    useEffect(() => {
        if(scroll) {
            const scrollInstance = scroll;

            scrollInstance.on('scroll', (instance) => {

                const currentScroll = instance.scroll.y;
                if(currentScroll > 100) {
                    changeNavBgState(false);
                }
                else{
                    changeNavBgState(true);
                }
            })

            // Remember to clean up when component unmounts
            return () => {
                scrollInstance.off('scroll');
            }
        }
    }, [scroll, location]); // Re-run when scroll instance changes

    const checkIfScrollToTop = (path) => {
        if(path === location.pathname){
            scroll.scrollTo(0, {duration: 1});
        }
    }

    console.log(settings);


    return (<div data-scroll data-scroll-sticky data-scroll-target="#page" className={"z-100 relative"}>
        <nav className={`navigation z-100 px-pagesmall medium:px-page laptop:px-pagelarge ${noImageHeader ? 'coloredBg' : (navBg ? 'transparentBg' : 'coloredBg')}`}>
            <div className="navigation-inner desktop:max-w-nav flex justify-between items-center mx-auto">
                <div className={`left flex-1 hidden tablet:block`}>
                    <div className={"text-base font-light"}>
                        {settings.navigation_subtitle}
                    </div>
                </div>
                <div className={`center tablet:flex-1 tablet:text-center z-100 tablet:z-10`}>
                    <Link to={"/"} onClick={() => {checkIfScrollToTop('/')}} className={"text-[.9rem] smallestest:text-lg font-semibold"}>
                        {settings.navigation_title}
                    </Link>
                    <div className={"text-[.9rem] smallestest:text-base font-light tablet:hidden -mt-1"}>
                        {settings.navigation_subtitle}
                    </div>
                </div>
                <div className={`right flex-1 z-100`}>
                    <div className={nav2 ? "burger close" : "burger"} onClick={() => {
                        changeNav();
                    }}>
                        <div className={"line bg-dark"}></div>
                        <div className={"line bg-dark"}></div>
                        <div className={"line bg-dark"}></div>
                    </div>
                </div>
            </div>
            <div className={`menu`}>
                <Menu className="menu__bg fade-in" ref={menuRef} nav={nav} onClick={() => {
                    changeNav();
                }}></Menu>
                <MenuInner className="menu__link__container" ref={menuInnerRef} nav={nav} nav2={nav2}>
                    <div className="links flex flex-col justify-between h-[80%] pl-pagesmall medium:pl-page laptop:pl-10 desktop:pl-20 my-auto">
                        <div className={"flex-1 flex justify-center flex-col mr-2"}>
                            {dienstleistungen.sort((a, b) => parseInt(a.node.order) - parseInt(b.node.order)).map(el => {
                                return (
                                    <Link
                                        to={"/" + el.node.slug.current}
                                        className={"font-extralight text-4xl desktop:text-5xl hover:-translate-y-0.5 duration-500 ease-in-out block mb-5"}
                                        onClick={() => {
                                            changeNav();
                                            checkIfScrollToTop('/' + el?.node?.slug?.current + '/')
                                        }}>
                                        {el.node.title}
                                    </Link>
                                );
                            })}
                            <Link to={"/about"} className={"font-extralight text-4xl desktop:text-5xl hover:-translate-y-0.5 duration-500 ease-in-out block mb-5"} onClick={() => {changeNav();checkIfScrollToTop('/about/')}}>Über mich</Link>
                            <Link to={"/kontakt"} className={"font-extralight text-4xl desktop:text-5xl hover:-translate-y-0.5 duration-500 ease-in-out block"} onClick={() => {changeNav();checkIfScrollToTop('/kontakt/')}}>Kontakt</Link>
                        </div>
                        <div className={"flex items-center mt-5 justify-start flex-wrap"}>
                            {settings.sociallinks.map((link, index) => {
                                return <a href={link.value} target={"_blank"} className={"mr-5"}>
                                    <Icon icon={link.type} iconColor={'#a6937a'}></Icon>
                                </a>
                            })}
                            <Link to="/impressum" className={"font-bold uppercase z-10 mr-5 relative text-brown text-base tracking-wider hover:-translate-y-0.5 duration-500 ease-in-out inline-block"} onClick={() => {changeNav();checkIfScrollToTop('/impressum/')}}>Impressum</Link>
                            <Link to="/datenschutz" className={"font-bold uppercase z-10 mr-2 relative text-brown text-base tracking-wider hover:-translate-y-0.5 duration-500 ease-in-out inline-block"} onClick={() => {changeNav();checkIfScrollToTop('/datenschutz/')}}>Datenschutzerklärung</Link>
                        </div>
                    </div>
                </MenuInner>
            </div>
            <DatenschutzAcceptance location={location} cookie={settings.cookies}></DatenschutzAcceptance>
        </nav>
    </div>)
}
export default NavigationAlt;
