import React from "react";
import {BsPlay, BsInstagram, BsFacebook, BsCamera, BsLinkedin} from 'react-icons/bs'
import { AiOutlineInstagram, AiOutlineCamera, AiOutlineYoutube } from 'react-icons/ai'
import { ImFilm } from 'react-icons/im'
import {FiLinkedin} from 'react-icons/fi'
import {FaXingSquare} from "react-icons/fa";

const Icon = ({icon, iconColor}) => {

    switch(icon){
        case 'play':
            return <BsPlay className={"play"} color={iconColor}/>;
        case 'camera':
            return <AiOutlineCamera color={iconColor}/>;
        case 'facebook':
            return <BsFacebook color={iconColor}/>;
        case 'instagram':
            return <AiOutlineInstagram color={iconColor}/>;
        case 'film':
            return <ImFilm color={iconColor}/>;
        case 'youtube':
            return <AiOutlineYoutube color={iconColor}/>;
        case 'linkedin':
            return <BsLinkedin size={18} color={iconColor}/>;
        case 'xing':
            return <FaXingSquare size={20} color={iconColor}/>;
    }
    return (
        <></>
    )
}

export default Icon;
