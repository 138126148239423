import React, {useEffect} from "react";
import MyPortableText from "../helper/MyPortableText";

const DatenschutzAcceptance = ({location, cookie}) => {
    const selectAll = () => {
        setCookie("danielasengstbratl-technical", true, 365);
        setCookie("danielasengstbratl-gdpr-google-analytics", true, 365);
        closePopup();
    }
    const selectSpecific = () => {
        setCookie("danielasengstbratl-technical", true, 365);
        const cb = document.querySelector('#analytics');
        if(cb.checked){
            setCookie('danielasengstbratl-gdpr-google-analytics', true, 365);
        } else{
            deleteCookie('danielasengstbratl-gdpr-google-analytics', '/');
        }
        closePopup();
    }

    function deleteCookie( name, path, domain ) {
        if( getCookie( name ) ) {
            document.cookie = name + "=" +
                ((path) ? ";path="+path:"")+
                ((domain)?";domain="+domain:"") +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
    }

    const closePopup = () => {
        document.querySelector(".datenschutzacceptance").classList.add("inactive");
        setTimeout(() => {
            document.querySelector(".datenschutzacceptance").style.display = "none"
        }, 300)
    };

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    useEffect(() => {
        if(getCookie('danielasengstbratl-technical') === ""){
            document.querySelector(".datenschutzacceptance").style.display = "flex"
        }
        if(getCookie("danielasengstbratl-gdpr-google-analytics")){
            const cb = document.querySelector('#analytics');
            cb.checked = true;
        }
    })

    return (
        <div className={`datenschutzacceptance`}>
            <div className={`datenschutzacceptance-inner`}>
                <h4 className={"lftetica uppercase datenschutzacceptance-title"}>{cookie?.title}</h4>
                <MyPortableText value={cookie?._rawText}></MyPortableText>
                <label className="datenschutzacceptance-cb">
                    <span className="lftetica">
                        Technisch
                    </span>
                    <input type="checkbox" value={"option"} checked disabled/>
                    <span className="checkmark"></span>
                </label>
                <label className="datenschutzacceptance-cb">
                    <span className="lftetica">
                        Google Analytics
                    </span>
                    <input type="checkbox" id={"analytics"} value={"option"}/>
                    <span className="checkmark"></span>
                </label>
                <div className="datenschutzacceptance-btns btn-container">
                    <div className={`acceptance-btn acceptance-btn-outline`} onClick={() => {selectSpecific()}}>{cookie?.button_selection}</div>
                    <div className={`acceptance-btn acceptance-btn-filled`} onClick={() => {selectAll()}}>{cookie?.button_all}</div>
                </div>
            </div>
        </div>
    )
}


export default DatenschutzAcceptance

