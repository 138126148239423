import React, {useEffect, useRef, useState} from "react";
import Icon from "../helper/Icon";
import {graphql, Link, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';
import DatenschutzAcceptance from "./DatenschutzAcceptance";
import {useLocomotiveScroll} from "react-locomotive-scroll";
import {AnimatePresence, motion} from "framer-motion";
const PageTransition = ({children, location}) => {

    const {scroll} = useLocomotiveScroll();

    return (<AnimatePresence initial={false} mode="wait">
        <motion.div key={location.pathname} initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{
                        ease: "easeInOut",
                        duration: .5
                    }}
                    onAnimationComplete={() => scroll.scrollTo(0, { duration: 0, disableLerp: true })}>
            {children}
        </motion.div>
    </AnimatePresence>)
}
export default PageTransition;
